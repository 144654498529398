// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Switch, Input, IconButton, InputAdornment } from '@mui/material';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './AnswerListItem.module.scss';

type Props = {
    classes: any,
    item: any,
    onChange: Function,
    onDelete: Function,
    connectDragPreview: Function,
    connectDragSource: Function,
};

type State = {};


class AnswerListItem extends React.Component {
    props: Props;
    state: State = {
        text: this.props.item.text,
    };
    timer = null;

    getInputId(path) {
        return this.props.item.id + '-answerListItem';
    }

    handleFormChange = (key) => (event) => {
        event.persist();
        const value = event.target.value;

        this.setState({ text: value });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) this.props.onChange(key)(event);
        }, 400);
    };

    render() {
        const {
            item,
            onDelete,
            onChange,
            connectDragPreview,
            connectDragSource,
            t,
        } = this.props;

        return (
            <div className={classes.root}>
                {connectDragPreview(
                    <div className={classes.wrapper}>
                        {connectDragSource(
                            <div className={classes.dragIcon}>
                                <DragHandleIcon className={classes.icon} />
                            </div>
                        )}
                        <Input
                            fullWidth={true}
                            id={'input-' + item.id}
                            
                            multiline
                            maxRows="4"
                            onChange={this.handleFormChange('text')}
                            placeholder={t('Answers text')}
                            value={this.state.text}
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    style={{ fontSize: '14px', opacity: '0.2' }}
                                >
                                    {255 - item.text.length}
                                </InputAdornment>
                            }
                        />
                        <Switch
                            classes={{
                                checked: classes.checked,
                                bar: classes.bar,
                            }}
                            checked={item.correct === true}
                            onChange={onChange('correct')}
                        />
                        <IconButton
                            className={classes.deleteButton}
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}

export default compose(withTranslation())(AnswerListItem);
