// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './EmployeeOverview.css';
import { push } from 'connected-react-router';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Fab,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Archive from '@mui/icons-material/Archive';
import { List } from 'immutable';
import jwtDecode from 'jwt-decode';

import {
    fetchEmployees,
    acknowledgeImportExtern,
    clearDiff,
    inviteEmployee,
    disableEmployee,
    editEmployee,
    upsertEmployee,
    deleteEmployee,
    switchRoleEmployee,
    upgradeSubscription,
    parseImportExtern,
} from '../../../actions';
import EmployeeDiffRow from '../../Employee/EmployeeOverview/EmployeeDiffRow';
import AppModule from '../../AppModule';
import EnhancedTable from '../../EnhancedTable';
import classnames from 'classnames';


import DialogChoose from '../../DialogChoose';
import ConfirmDialog from '../../ConfirmDialog';

import { withRouter } from 'react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import UpgradeDialog from '../../Abonnement/UpgradeDialog/UpgradeDialog';
import getRoles from '../../../selectors/getRoles';
import { inProgress } from '../../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ExternOverview.module.scss';

type Props = {
    loading: boolean,
    employees: Array<any>,
    user: mixed,
    parseImport: (file: any) => void,
    acknowledgeImportExtern: (file: any) => void,
    clearDiff: () => void,
    inviteEmployee: (employeeId: number) => void,
    disableEmployee: (employee: TEmployee) => void,
    deleteEmployee: (employee: TEmployee) => void,
    editEmployee: (employee?: TEmployee) => void,
    startEditEmployee: (employee: TEmployee) => void,
    onSave: (employee?: TEmployee) => void,
    editemployee: TEmployee,
    inProgress: boolean,
    diff: { removed: List<TEmployee>, added: List<TEmployee> },
    display: boolean,
    displayType: string,
    vv_functions: boolean,
};

type State = {
    showUpload: boolean,
    showDialogChoose: boolean,
    showConfirmDialog: boolean,
    showUpgradeDialog: boolean,
    subscriptionUpgraded: boolean,
    emplToDelete?: TEmployee,
    searchtext: string,
};


//Rare methode. Als je hierin een error krijgt werken de props niet meer zonder enige waarschuwing!
const getSubscriptionUpgrade = (subscription, subscriptionStubs) => {
    if (subscription && subscriptionStubs && subscriptionStubs.prijzen) {
        const prijzen = subscriptionStubs.prijzen;
        for (let i = 0; i < prijzen.length; i++) {
            const prijs = prijzen[i];
            if (prijs.maxEmployees > subscription.maxEmployees) {
                return prijs;
            }
        }

        const maxPrijs = prijzen[prijzen.length - 1]; // Max grootte
        maxPrijs.maxEmployees = -1;
        return maxPrijs;
    }
    return null;
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        loading: !inProgress(store),
        diff: store.drafts.employees.diff,
        inProgress: store.drafts.employees.inProgress,
        user: jwtDecode(store.auth.token),
        editemployee: store.drafts.employees.editemployee,
        deleteemployee: store.drafts.employees.deleteemployee,
        subscription: subscription,
        subscriptionUpgrade: getSubscriptionUpgrade(
            subscription,
            store.subscription.subscriptionStubs
        ),
        roles: getRoles(store),
        subscriptionHolder: store.dashboard.dashboard.subscriptionHolderName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        parseImport: bindActionCreators(parseImportExtern, dispatch),
        acknowledgeImportExtern: bindActionCreators(
            acknowledgeImportExtern,
            dispatch
        ),
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        disableEmployee: bindActionCreators(disableEmployee, dispatch),
        deleteEmployee: bindActionCreators(deleteEmployee, dispatch),
        editEmployee: bindActionCreators(editEmployee, dispatch),
        switchRoleEmployee: bindActionCreators(switchRoleEmployee, dispatch),
        upgradeSubscription: bindActionCreators(upgradeSubscription, dispatch),
        newEmployee: () => {
            dispatch(push('/medewerkers/aanmaken'));
        },
        clearDiff: () => {
            dispatch(clearDiff());
        },
        onSave: (employee, callback) => {
            upsertEmployee(employee, callback)(dispatch);
            dispatch(push('/medewerkers'));
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('extern'));
        },
        goToDetailView: (medewerkerId) => {
            dispatch(push('/medewerker/details/' + medewerkerId));
        },
    };
};

class ExternOverview extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        searchtext: '',
        actions: [
            {
                id: 'edit',
                label: 'Wijzigen',
                isVisible: (id) => this.isVisible(id),
            },
            //{ id: 'invite', label: 'Uitnodigen voor de app', isVisible: id => !this.isInvited(id) },
            //{ id: 'invite', label: 'Opnieuw uitnodigen voor de app', isVisible: id => this.isInvited(id) },
            {
                id: 'disable',
                label: 'Deactiveer',
                isVisible: (id) => {
                    return this.isInvited(id) && !this.isCurrentUser(id);
                },
            },
            {
                id: 'delete',
                label: 'Externe verwijderen',
                isVisible: (id) => {
                    return !this.isCurrentUser(id) && this.isDeletable(id);
                },
            },
        ],
        columns: [
            { id: 'externalId', numeric: false, size: '8%', label: 'Nr' },
            { id: 'firstname', numeric: false, size: '10%', label: 'Naam' },
            {
                id: 'lastname',
                numeric: false,
                size: '13%',
                label: 'Achternaam',
            },
            {
                id: 'externalusername',
                numeric: false,
                size: '22%',
                label: 'E-mail',
            },
            {
                id: 'phonenumber',
                numeric: false,
                size: '11%',
                label: 'Telefoonnummer',
            },
            { id: 'import', numeric: false, size: '64px', label: 'Type' },
        ],
        showUpload: false,
        showDialogChoose: false,
        showConfirmDialog: false,
        showUpgradeDialog: false,
        showKamUpgradeDialog: false,
        subscriptionUpgraded: false,
    };

    getEmployee = (employeeId: number): ?TEmployee => {
        return this.props.employees.find((empl) => empl.id === employeeId);
    };

    isVisible = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl) {
            return empl.importType !== 'IMPORT';
        }
        return true;
    };

    isKam = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && empl.roles) {
            const roles = empl.roles;
            return ~roles.indexOf('COORDINATOR');
        }
        return false;
    };

    isSubscriptionHolder = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && empl.roles) {
            const roles = empl.roles;
            return ~roles.indexOf('SUBSCRIPTIONHOLDER');
        }
        return false;
    };

    isDeletable = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl) {
            return empl.importType === 'MANUAL';
        }
        return false;
    };

    isCurrentUser = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl) {
            return empl.id === this.props.user.id;
        }
        return false;
    };

    isInvited = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && !~empl.roles.indexOf('COORDINATOR')) {
            return empl.state === 'ACTIVE';
        } else if (empl && ~empl.roles.indexOf('COORDINATOR')) {
            if (empl.invited === false) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    handleAction = (event, action) => {
        const { t } = this.props;
        if (action.id === 'edit') {
            this.props.startEditEmployee(action.rowId);
        } else if (action.id === 'invite') {
            const { subscription, employees, roles } = this.props;

            const activeEmployees = employees.filter(
                (e) => e.state === 'ACTIVE'
            );

            if (
                this.getEmployee(action.rowId).state !== 'ACTIVE' &&
                activeEmployees.length >= subscription.maxEmployees &&
                subscription.maxEmployees !== -1
            ) {
                if (roles.indexOf('SUBSCRIPTIONHOLDER') > -1) {
                    this.setState({
                        showUpgradeDialog: true,
                    });
                } else {
                    this.setState({
                        showKamUpgradeDialog: true,
                    });
                }
            } else {
                this.props.inviteEmployee(action.rowId);
            }
        } else if (action.id === 'disable') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                this.props.disableEmployee(empl, t);
            }
        } else if (action.id === 'delete') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                this.setState({ emplToDelete: empl });
                this.showConfirmDialog();
            }
        } else if (action.id === 'switchKAM') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                if (!~empl.roles.indexOf('COORDINATOR')) {
                    this.setState({ openDialog: true, empl: empl });
                } else {
                    this.props.switchRoleEmployee(empl, 'COORDINATOR');
                }
            }
        }
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });

        this.props.fetchEmployees();
    };

    // For restoring page and rowsPerPage list->detail->list flow
    setPage = (page) => {
        console.log("INFO: ExternOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ExternOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    mapEmployeeToRow = (employee) => {
        const { t } = this.props;
        return {
            ...employee,
            state: this.employeeStateToText(employee.state),
            role: this.employeeRoleToText(employee.roles),
            lastname:
                employee.lastname +
                (employee.middlename && employee.middlename !== ''
                    ? ', ' + employee.middlename
                    : ''),
            import:
                employee.importType === 'MANUAL' || !employee.importType ? (
                    <div className={'tooltip'}>
                        <span className={'tooltiptext'}>
                            {t('Manual added')}
                        </span>
                        <PersonAdd   />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span className={'tooltiptext'}>
                            {t('Added trough CSV import')}
                        </span>
                        <Archive   />
                    </div>
                ),
            showMenu: employee.importType === 'MANUAL',
        };
    };

    employeeRoleToText = (roles) => {
        if (roles && ~roles.indexOf('COORDINATOR')) {
            return 'KAM';
        }
        return '';
    };

    employeeStateToText = (state) => {
        const { t } = this.props;
        let text = '';

        switch (state) {
            case 'ADDED':
                text = t('Inactive');
                break;
            case 'ACTIVE':
                text = t('Active');
                break;
            case 'INACTIVE':
                text = t('Inactive');
                break;
            default:
        }

        return <div className={'border-text ' + state}>{text}</div>;
    };

    openUploadDialog = () => {
        this.setState({
            showUpload: true,
        });
        this.closeChooseDialog();
    };

    openChooseDialog = () => {
        this.setState({
            showDialogChoose: true,
        });
    };

    closeChooseDialog = () => {
        this.setState({
            showDialogChoose: false,
        });
    };

    handleUpload = (file: any) => {
        this.props.parseImport(file);
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    acknowledgeImportExtern = (file: any) => {
        this.props.acknowledgeImportExtern(file);
        this.closeChooseDialog();
        this.props.clearDiff();
    };

    hideDialogChoose = () => {
        this.props.clearDiff();
        this.setState({
            showDialogChoose: false,
        });
    };

    mapToDiffRow = (list: List<TEmployee>) => {
        return list.map((employee) => (
            <EmployeeDiffRow employee={employee} key={employee.externalId} />
        ));
    };

    mapToConflictRow = (list: List<TEmployee>) => {
        return list.map((employee) => (
            <EmployeeDiffRow
                employee={employee}
                key={employee.externalId}
                conflictRow={employee.conflict}
            />
        ));
    };

    componentDidMount() {
        if (this.props.location.hash === '#new') {
            this.openChooseDialog();
        }
        this.setState({
            actions: [
                {
                    id: 'edit',
                    label: 'Change',
                    isVisible: (id) => this.isVisible(id),
                },
                {
                    id: 'disable',
                    label: 'Deactivate',
                    isVisible: (id) => {
                        return this.isInvited(id) && !this.isCurrentUser(id);
                    },
                },
                {
                    id: 'delete',
                    label: 'Delete external',
                    isVisible: (id) => {
                        return !this.isCurrentUser(id) && this.isDeletable(id);
                    },
                },
            ],
            columns: [
                { id: 'externalId', numeric: false, size: '8%', label: 'Nr' },
                {
                    id: 'firstname',
                    numeric: false,
                    size: '10%',
                    label: 'Name',
                },
                {
                    id: 'lastname',
                    numeric: false,
                    size: '13%',
                    label: 'Last name',
                },
                {
                    id: 'externalusername',
                    numeric: false,
                    size: '22%',
                    label: 'Emailadres',
                },
                {
                    id: 'phonenumber',
                    numeric: false,
                    size: '11%',
                    label: 'Telephone number',
                },
                {
                    id: 'import',
                    numeric: false,
                    size: '64px',
                    label: 'Type',
                },
            ],
        });

        // If url params rows and page go to right page
        if (this.props.location && this.props.location.search && this.props.location.search.includes("page")) {
            const jumpToPage = new URLSearchParams(this.props.location.search).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({ startPage: Number(jumpToPage)});
        }
        if (this.props.location && this.props.location.search && this.props.location.search.includes("rows")) {
            const startRowsPerPage = new URLSearchParams(this.props.location.search).get("rows");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({ startRowsPerPage: Number(startRowsPerPage)});
        }

    }

    closeUpgradeDialog = () => {
        this.setState({
            showUpgradeDialog: false,
            subscriptionUpgraded: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({ openDialog: false, empl: null });
    };

    confirmKAM = () => {
        this.props.switchRoleEmployee(this.state.empl, 'COORDINATOR');
        this.setState({ openDialog: false, empl: null });
    };

    upgradeSubscription = () => {
        this.props.upgradeSubscription((err) => {
            if (!err) {
                this.props.fetchCurrentUser();
                this.setState({
                    subscriptionUpgraded: true,
                });
            }
        });
    };

    employeeSorter = () => {
        return {
            externalId: (a, b) => {
                const numberA = parseInt(a.externalId, 10);
                const numberB = parseInt(b.externalId, 10);

                if (isNaN(numberA) && !isNaN(numberB)) {
                    return 1;
                } else if (!isNaN(numberA) && isNaN(numberB)) {
                    return -1;
                } else if (!isNaN(numberA) && !isNaN(numberB)) {
                    return numberA - numberB;
                } else {
                    return a.externalId > b.externalId ? 1 : -1;
                }
            },
            role: (a, b) => {
                if (
                    ~a.roles.indexOf('COORDINATOR') &&
                    !~b.roles.indexOf('COORDINATOR')
                ) {
                    return 1;
                } else if (
                    !~a.roles.indexOf('COORDINATOR') &&
                    ~b.roles.indexOf('COORDINATOR')
                ) {
                    return -1;
                }
                return 0;
            },
            firstname: (a, b) => {
                a = a.firstname.toLowerCase();
                b = b.firstname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            lastname: (a, b) => {
                a = a.lastname.toLowerCase();
                b = b.lastname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            import: (a, b) => {
                a = a.importType;
                b = b.importType;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            username: null,
            phonenumber: null,
        };
    };

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    render() {
        const {
            loading,
            employees,
            subscription,
            subscriptionUpgrade,
            subscriptionHolder,
            vv_functions,
            t,
        } = this.props;
        const {
            actions,
            columns,
            showUpgradeDialog,
            subscriptionUpgraded,
            showKamUpgradeDialog,
        } = this.state;

        let totalEmployees;
        if (vv_functions) {
            totalEmployees = employees.length + ' ' + t('External employees');
        } else {
            totalEmployees =
                employees.length +
                ' ' +
                t('employee') +
                (employees.length === 1 ? '' : 's');
        }

        const searchresults = employees.filter((row) => {
            return (
                (row.fullname &&
                    row.fullname
                        .replace('  ', ' ')
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.username &&
                    row.username
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.phonenumber &&
                    row.phonenumber
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.externalId &&
                    row.externalId
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1)
            );
        });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search in employees')}
                onSearchChange={this.handleSearch}
                title={
                    totalEmployees +
                    ' ' +
                    (this.state.searchtext.length > 0
                        ? (searchresults.length === 1
                              ? ' - ' +
                                searchresults.length +
                                ' ' +
                                t('searchresult')
                              : '') +
                          (searchresults.length > 1 ||
                          searchresults.length === 0
                              ? ' - ' +
                                searchresults.length +
                                ' ' +
                                t('searchresults')
                              : '')
                        : '')
                }
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <UpgradeDialog
                        open={showUpgradeDialog}
                        handleClose={this.closeUpgradeDialog}
                        onUpgrade={this.upgradeSubscription}
                        subscription={subscription}
                        subscriptionUpgrade={subscriptionUpgrade}
                        subscriptionUpgraded={subscriptionUpgraded}
                    />
                    <Dialog
                        onClose={() => {
                            this.setState({ showKamUpgradeDialog: false });
                        }}
                        open={showKamUpgradeDialog}
                    >
                        <DialogTitle>
                            {t('Can not invite employee')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('Reached maximum users')}.{' '}
                                {t('Contact subscription holder')}(
                                {subscriptionHolder}){' '}
                                {t('to upgrade subscription')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        showKamUpgradeDialog: false,
                                    });
                                }}
                            >
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <DialogChoose
                        open={this.state.showDialogChoose}
                        handleClose={this.hideDialogChoose}
                        onUpload={this.handleUpload}
                        diff={this.props.diff}
                        acknowledgeImportExtern={this.acknowledgeImportExtern}
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.mapToDiffRow}
                        mapToConflictRow={this.mapToConflictRow}
                        editEmployeeFunction={this.props.editEmployee}
                        editemployee={this.props.editemployee}
                        onSave={this.props.onSave}
                        display={this.props.display}
                        extern={true}
                        employees={this.props.employees}
                    />
                    <ConfirmDialog
                        open={this.state.showConfirmDialog}
                        handleClose={this.hideConfirmDialog}
                        deleteemployee={this.state.emplToDelete}
                        deleteEmployeeFunction={this.props.deleteEmployee}
                        display={this.props.display}
                    />
                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <Fab
                             
                            onClick={this.openChooseDialog}
                        >
                            <AddIcon />
                        </Fab>
                    </div>
                    <EnhancedTable
                        columns={columns}
                        defaultOrder="desc"
                        defaultOrderBy={'externalId'}
                        rows={searchresults}
                        formatter={this.mapEmployeeToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.employeeSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                      'No employees, add employees with plus sign'
                                  )
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.cancelEdit}
                        TransitionComponent={this.Transition}
                        className="victor"
                    >
                        <DialogTitle className="title">
                            {t('Make KAM employee?')}
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                 
                                className="button cancel"
                                onClick={this.closeConfirmDialog}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                 
                                className="button add"
                                onClick={this.confirmKAM}
                            >
                                {t('Make KAM')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(ExternOverview))
);
