// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {setCrumbPath} from '../../../actions';
import classes from './IncidentEditView.module.scss';
import i18n from "i18next";
import moment from "../../../lib/moment";
import {
    Button,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Switch
} from "@mui/material";
import TitleDescription from "../../TitleDescription";
import WarningIcon from "@mui/icons-material/Warning";
import colors from "../../../lib/themes/theme.scss";
import Select from "@mui/material/Select";
import SimpleImageInput from "../../SimpleImageInput";
import DeleteIcon from "@mui/icons-material/Delete";
import {inProgress} from "../../../selectors";
import {fetchIncidentCause, fetchIncidentTypes, setViewkey, saveIncident} from "../../../actions";
import {push} from "connected-react-router";
import {percentColors1} from "../../../lib/utils";
import LocationPicker from "../../Util/Maps/LocationPicker";
import classnames from "classnames";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-US";
import PhotoViewer from "../../PhotoViewer";


type Props = {
};

type State = {

};

const viewkey: string = 'INCIDENT_VIEW';

const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        incidents: store.entities.incidents,
        incidenttype: store.entities.incidenttype,
        incidentcause: store.entities.incidentcause,
        employees: store.entities.employees,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Incident' }));
        },
        fetchIncidentTypes: () => {
            dispatch(fetchIncidentTypes());
        },
        fetchIncidentCause: () => {
            dispatch(fetchIncidentCause());
        },
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        saveIncident: (data) => {
            dispatch(saveIncident(data));
        },
        goTo: (path, state) => {
            dispatch(push(path, state));
        }
    };
};

class IncidentEditView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        columns: [],
        actions: [],
        searchtext: '',
        incidentOpen: false,
        imageVisible: false,
        activePhotoId: undefined,
        editMode: false,
        showDeleteDialog: false,
        anchorEl: 0,
        openType: false,
        typeFilter: 0,
        openCause: false,
        causeFilter: 0,
        openEmployees: false,
        employeesFilter: 0,
        openMaatregel: false,
        maatregelFilter: 0,
        exportOpen: false,
        situatieFilter: 0,
        openSituatie: false,
        openWarning: false,
        showResolveDialog: false,
        incidentToResolveComment: null,
        incidentToResolveSwitch: null,
        latlon: undefined,
    };


    componentDidMount() {
        this.props.setViewkey();

        this.loadData();
        if (this.props.incident) {
            let myDate = moment(this.props.incident.date).toDate();

            this.setState({
                myDate: myDate,
                incident: this.props.incident,
                incidentToResolveComment: this.props.incident.kamComment,
                incidentToResolveSwitch: this.props.incident.kamResolved,
            });


        }
        if (this.props.incident.lat && this.props.incident.lon) {
            this.setState({
                latlon: {
                    lat: this.props.incident.lat,
                    lng: this.props.incident.lon,
                }
            })
        }
    }

    loadData() {
        this.props.fetchIncidentTypes();
        this.props.fetchIncidentCause();

    }



    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    editMode = () => {
        this.setState({
            editMode: true,
        });
    };

    save = () => {
        if (
            this.state.incident.remark.text.length > 0 &&
            this.state.incident.type !== null &&
            this.state.incident.cause !== null &&
            this.state.incident.cause !== undefined &&
            this.state.incident.cause_comment.length > 0
        ) {
            this.props.saveIncident(this.state.incident);
            this.setState({
                editMode: false,
            });
        } else {
            this.setState({
                openWarning: true,
            });
        }
    };

    cancelEditMode = () => {
        this.setState({
            editMode: false,
        });
    };



    handleAction = (event, action) => {
        if (action.id === 'view') {
            // window.$crisp.push(['do', 'chat:hide']);
            console.log('XXX action: ', action);
            const incident = this.props.incidents.byId[action.rowId];
            const state = {
                incident: incident
            };

            this.props.goTo('/registratiemelding/details/' + incident.id, state );

        } else if (action.id === 'edit') {
            // window.$crisp.push(['do', 'chat:hide']);

            this.setState({
                editMode: true,
                incidentOpen: true,
                incident: this.props.incidents.byId[action.rowId],
            });
        } else if (action.id === 'delete') {
            // window.$crisp.push(['do', 'chat:hide']);

            this.setState({
                incidentToDelete: this.props.incidents.byId[action.rowId],
                incident: this.props.incidents.byId[action.rowId],
                showDeleteDialog: true,
            });
        } else if (action.id === 'markResolved') {
            // window.$crisp.push(['do', 'chat:hide']);
            let incident = this.props.incidents.byId[action.rowId];
            this.setState({
                incident: incident,
                incidentToResolveComment: incident.kamComment,
                incidentToResolveSwitch: incident.kamResolved,
                showResolveDialog: true,
            });
        } else if (action.id === 'getPDF') {
            console.log('getPDF');
            this.downloadPdf(this.props.incidents.byId[action.rowId])

        }
    };

    editKamStatus = (key) => (event) => {
        if (key === 'kamComment') {
            this.setState({
                incidentToResolveComment: event.target.value
            });
        } else if (key === 'kamSwitch') {
            this.setState({
                incidentToResolveSwitch: !this.state.incidentToResolveSwitch
            });

        } else if (key === 'kamSave') {
            // Update incident
            let incident = this.props.incidents.byId[this.state.incident.id];
            incident.kamComment =  this.state.incidentToResolveComment;
            incident.kamResolved =  this.state.incidentToResolveSwitch;

            this.props.saveIncident(incident);

            this.setState({
                showResolveDialog: false
            });
        }
    }

    editIncident = (key) => (event) => {
        if (key === 'slachtoffer') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    slachtoffer: event.target.value,
                },
            });
        } else if (key === 'remark') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    remark: {
                        ...this.state.incident.remark,
                        text: event.target.value,
                    },
                },
            });
        } else if (key === 'cause-comment') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    cause_comment: event.target.value,
                },
            });
        } else if (key === 'maatregelen') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    maatregelen: event.target.value,
                },
            });
        } else if (key === 'unsafe') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    remark: {
                        ...this.state.incident.remark,
                        unsafe: !this.state.incident.remark.unsafe,
                    },
                },
            });
        } else if (key === 'type') {
            this.setState({
                incident: { ...this.state.incident, type: event.target.value },
            });
        } else if (key === 'cause') {
            this.setState({
                incident: { ...this.state.incident, cause: event.target.value },
            });
        } else if (key === 'resolvedByKam') {
            this.setState({
                incident: {
                    ...this.state.incident,
                    kamResolved: !this.state.incident.kamResolved
                },
            });
        } else if (key === 'kam-comment') {
            this.setState({
                incident: { ...this.state.incident,kamComment: event.target.value },
            });
        }
    };

    handleDateChange = (event) => {
        this.setState({ myDate: event });

        let myDate = moment(event).format('YYYY-MM-DDTHH:mm:ssZ');

        this.setState({ incident: {
            ...this.state.incident,
            date: myDate,
        }});

    }
    handleLocationChange = (latlon, address) => {
        if (!latlon) {
            latlon = this.state.latlon;
        }

        if (!address) {
            address = this.state.incident.address;
        }
        console.log(latlon);
        this.setState({
            incident: {
                ...this.state.incident,
                address: address,
                lat: latlon.lat,
                lon: latlon.lng,
            },
        });
        this.setState({ latlon: latlon});
    };


    render() {
        const { loading, t } = i18n;
        const { incidenttype, incidentcause } = this.props;
        const { incident } = this.state;



        if (!incidentcause) {
            return 'loading';
        }

        let myLocale = nl;
        if (t('LOCALE') === 'en') {
            myLocale = en;
        }

        return (
            <div tabIndex={0} role="button">
                {!this.state.editMode ? (
                    <div style={{ width: '100%' }}>
                        <div>
                            <p
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '24px',
                                    float: 'left',
                                }}
                            >
                                {incident
                                    ? moment(incident.date).format('LLL') + ', ' +
                                    (incident.anonymous ? t('anonymous') : incident.user.fullname)
                                    : '-'}
                            </p>
                        </div>
                        <Divider style={{ width: '100%' }} />

                        <Button
                            onClick={this.editMode}
                            style={{
                                color: '#0076FF',
                                float: 'right',
                                marginRight: '24px',
                                marginTop: '10px',
                            }}
                        >
                            {t('Change data')}
                        </Button>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Notification details')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Type of report')}
                                itemDescription={
                                    incident && incident.type && incidenttype
                                        ? t(incidenttype.byId[incident.type].locKey ||
                                            incidenttype.byId[this.state.incident.type].name)
                                        : '-'
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={`${t('Victim')} / ${t('Affected')} / ${t('Customer')}`}
                                itemDescription={
                                    incident && incident.slachtoffer
                                        ? incident.slachtoffer
                                        : '-'
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Description')}
                                itemDescription={
                                    incident && incident.remark.text
                                        ? incident.remark.text
                                        : '-'
                                }
                            />
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Cause details')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Type of cause')}
                                itemDescription={
                                    incident && incident.cause && this.props.incidentcause
                                        ? t(
                                            this.props.incidentcause.byId[incident.cause].locKey ||
                                            this.props.incidentcause.byId[incident.cause].name
                                        )
                                        : '-'
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Explanation of cause')}
                                itemDescription={
                                    incident && incident.cause_comment
                                        ? incident.cause_comment
                                        : '-'
                                }
                            />
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Action taken immediately')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Measure')}
                                itemDescription={
                                    incident && incident.maatregelen
                                        ? incident.maatregelen
                                        : '-'
                                }
                            />
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Situation')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Is situation unsave?')}
                                itemDescription={
                                    incident && incident.remark.unsafe
                                        ? t('Yes')
                                        : t('No')
                                }
                            />
                            {incident &&
                                incident.remark.unsafe && (
                                    <div
                                        className={'border-text'}
                                        style={{
                                            backgroundColor: getColorForPercentage(
                                                0
                                            ),
                                            borderColor: getColorForPercentage(
                                                0
                                            ),
                                            width: '120px',
                                            fontWeight: '300',
                                            float: 'right',
                                            marginTop: '20px',
                                            marginRight: '30px',
                                        }}
                                    >
                                                <span>
                                                    <WarningIcon
                                                        style={{
                                                            width: '14px',
                                                            height: '14px',
                                                            position:
                                                                'relative',
                                                            top: '2px',
                                                            marginRight: '5px',
                                                        }}
                                                    />
                                                    {t('Unsafe situation')}
                                                </span>
                                    </div>
                                )}
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Location')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Location')}
                                itemDescription={
                                    incident && incident.address
                                        ? incident.address
                                        : '-'
                                }
                            />
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Attachments')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Images')}
                                itemDescription={
                                    incident && incident.remark.photos.length > 0
                                        ? ''
                                        : '-'
                                }
                            />
                            {incident &&
                                incident.remark.photos.length > 0 && (
                                    <div
                                        style={{
                                            maxWidth: '550px',
                                            marginTop: '16px',
                                        }}
                                    >
                                        <PhotoViewer photos={incident.remark.photos} float={null} padding={null}/>
                                    </div>
                                )}
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                                marginBottom: '24px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('KAM Comment')}</b>
                            </p>
                            <p>
                                {incident ? incident.kamComment : '-'}
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Resolved by KAM?')}
                                itemDescription={
                                    incident &&
                                    incident.kamResolved
                                        ? t('Yes')
                                        : t('No')
                                }
                            />

                        </div>


                    </div>
                ) : (
                    <div style={{ width: '100%' }}>
                        <div>
                            <p
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '24px',
                                    float: 'left',
                                }}
                            >
                                {incident
                                    ? moment(incident.date).format('LLL') + ', ' +
                                    incident.user.fullname +' (' +t('Edit') +')'
                                    : '-'}
                            </p>
                        </div>
                        <Divider style={{ width: '100%' }} />

                        <div
                            style={{ height: '56px', backgroundColor: colors.primaryColor,}}
                        >

                            <Button
                                onClick={this.save}
                                style={{
                                    color: '#0076FF',
                                    float: 'right',
                                    marginRight: '24px',
                                    marginTop: '10px',
                                }}
                            >
                                {t('Save')}
                            </Button>

                            <Button
                                onClick={this.cancelEditMode}
                                style={{
                                    color: '#0076FF',
                                    float: 'right',
                                    marginRight: '12px',
                                    marginTop: '10px',
                                }}
                            >
                                {t('Cancel')}
                            </Button>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Notification details')}</b>
                            </p>
                            <FormControl
                                className={classes.item}
                                style={{ width: '100%' }}
                            >
                                <InputLabel>
                                    {t('Type of report')}*
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    value={incident && incident.type
                                            ? incident.type
                                            : ''
                                    }
                                    onChange={this.editIncident('type')}
                                >
                                    {incidenttype &&
                                        incidenttype.allIds.map(
                                            (type) => {
                                                return (
                                                    <MenuItem
                                                        key={incidenttype.byId[type].id}
                                                        value={incidenttype.byId[type].id}
                                                    >
                                                        <ListItemText
                                                            primary={t(incidenttype.byId[type].locKey ||
                                                                incidenttype.byId[type].name
                                                            )}
                                                        />
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                </Select>
                            </FormControl>
                            <FormControl
                                className={classes.item}
                            >
                                <InputLabel htmlFor="incident-slachtoffer">
                                    {t('Victim')} / {t('Affected')} /{' '}
                                    {t('Customer')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}

                                    id="incident-slachtoffer"
                                    value={
                                        this.state.incident &&
                                        this.state.incident.slachtoffer
                                            ? this.state.incident.slachtoffer
                                            : ''
                                    }
                                    onChange={this.editIncident('slachtoffer')}
                                />
                            </FormControl>
                            <FormControl
                                className={classes.item2}
                            >
                                <InputLabel htmlFor="incident-description">
                                    {t('Description')}*
                                </InputLabel>
                                <Input
                                    fullWidth={true}

                                    id="incident-description"
                                    value={this.state.incident &&
                                        this.state.incident.remark.text
                                            ? this.state.incident.remark.text
                                            : ''
                                    }
                                    onChange={this.editIncident('remark')}
                                    multiline
                                />
                            </FormControl>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Cause details')}</b>
                            </p>
                            <FormControl
                                className={classes.item}
                            >
                                <InputLabel>
                                    {t('Type of cause')}*
                                </InputLabel>
                                <Select
                                    variant="standard"
                                    value={this.state.incident &&
                                        this.state.incident.cause
                                            ? this.state.incident.cause
                                            : ''
                                    }
                                    onChange={this.editIncident('cause')}
                                >
                                    {incidentcause &&
                                        incidentcause.allIds.map(
                                            (cause) => {
                                                return (
                                                    <MenuItem
                                                        key={incidentcause.byId[cause].id}
                                                        value={incidentcause.byId[cause].id}
                                                    >
                                                        <ListItemText
                                                            primary={t(incidentcause.byId[cause].locKey ||
                                                                incidentcause.byId[cause].locKey.name
                                                            )}
                                                        />
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                </Select>
                            </FormControl>
                            <FormControl
                                className={classes.item3}
                            >
                                <InputLabel htmlFor="incident-cause-comment">
                                    {t('Enter explanation cause')}*
                                </InputLabel>
                                <Input
                                    fullWidth={true}

                                    id="incident-cause-comment"
                                    value={this.state.incident &&
                                        this.state.incident.cause_comment
                                            ? this.state.incident.cause_comment
                                            : ''
                                    }
                                    onChange={this.editIncident(
                                        'cause-comment'
                                    )}
                                    multiline
                                />
                            </FormControl>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Action taken immediately')}</b>
                            </p>
                            <FormControl
                                className={classes.item}
                                style={{ width: '505px' }}
                            >
                                <InputLabel htmlFor="incident-maatregel">
                                    {t('Measures')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}

                                    id="incident-maatregel"
                                    value={
                                        this.state.incident &&
                                        this.state.incident.maatregelen
                                            ? this.state.incident.maatregelen
                                            : ''
                                    }
                                    onChange={this.editIncident('maatregelen')}
                                    multiline
                                />
                            </FormControl>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                width: '100%',
                                height: '460px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Location')}</b>
                            </p>
                            <LocationPicker
                                location={this.state.latlon}
                                hintText={t('Incident location')}
                                onChange={this.handleLocationChange}
                                address={
                                    this.state.incident.address
                                }
                            />
                        </div>


                        <div
                            style={{
                                marginTop: '84px',
                                marginLeft: '24px',
                                width: '100%',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Date')}</b>
                            </p>
                            <FormControl
                                className={classnames(
                                    {
                                        [classes.datePickerControl]: true,
                                        [classes.datePickerVisible]: true,
                                    }
                                )}
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale}>
                                    <DateTimePicker
                                        id="Incident-date"
                                        ampm={false}
                                        okLabel={t('Ok')}
                                        cancelLabel={t('Cancel')}
                                        label={t('Choose date')}
                                        fullWidth={true}
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        inputFormat={t('DateTimeFormat')}
                                        onChange={this.handleDateChange}
                                        value={this.state.myDate}
                                    />
                                </LocalizationProvider>
                                {/*<FormHelperText className={classes.dateHelperText}>De datum waarop de werkplekinspectie gereed moet zijn</FormHelperText>*/}
                            </FormControl>
                        </div>



                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Situation')}</b>
                            </p>
                            <p style={{ marginBottom: '-4px' }}>
                                {t('Is situation unsave?')}{' '}
                                <div
                                    style={{
                                        float: 'right',
                                        marginTop: '-20px',
                                        marginRight: '50px',
                                    }}
                                >
                                    <Switch
                                        checked={
                                            this.state.incident &&
                                            this.state.incident.remark.unsafe
                                        }
                                        onChange={this.editIncident('unsafe')}
                                        id="unsafe"
                                    />
                                </div>
                            </p>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('Attachments')}</b>
                            </p>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Photos')}
                                itemDescription={this.state.incident &&
                                    this.state.incident.remark.photos.length > 0
                                        ? ''
                                        : '-'
                                }
                            />
                            {this.state.incident &&
                                this.state.incident.remark.photos.length > 0 && (
                                    <div
                                        style={{
                                            maxWidth: '550px',
                                            marginTop: '16px',
                                        }}
                                    >
                                        <p style={{fontSize: '16px'}}><b>{'Foto(s) toevoegen'}</b></p>
                                        {(!loading) && <div style={{display: 'inline-block'}}>
                                            <div style={{float: 'left', marginRight: '10px', marginTop: '10px', position: 'relative'}}><SimpleImageInput id={'incidentphoto0'} onChange={this.handleImageUpload} url={(this.state.incident && this.state.incident.remark.photos[0]) ? this.state.incident.remark.photos[0].url : ''} />
                                                {(this.state.incident && this.state.incident.remark.photos[0]) && <IconButton   className="drawer-button" onClick={() => this.handleRemoveImage(0)} style={{position: 'absolute', bottom: '-5px', right: '-20px'}}>
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>}</div>
                                            {this.state.incident && this.state.incident.remark.photos[0] && <div style={{float: 'left', marginRight: '10px', marginTop: '10px', position: 'relative'}}><SimpleImageInput id={'incidentphoto1'} onChange={this.handleImageUpload} disabled={false} url={(this.state.incident && this.state.incident.remark.photos[1]) ? this.state.incident.remark.photos[1].url : ''} />
                                                {(this.state.incident && this.state.incident.remark.photos[1]) && <IconButton   className="drawer-button" onClick={() => this.handleRemoveImage(1)} style={{position: 'absolute', bottom: '-5px', right: '-20px'}}>
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>}</div>}
                                            {this.state.incident && this.state.incident.remark.photos[1] && <div style={{float: 'left', marginRight: '10px', marginTop: '10px', position: 'relative'}}><SimpleImageInput id={'incidentphoto2'} onChange={this.handleImageUpload} url={(this.state.incident && this.state.incident.remark.photos[2]) ? this.state.incident.remark.photos[2].url : ''} />
                                                {(this.state.incident && this.state.incident.remark.photos[2]) && <IconButton   className="drawer-button" onClick={() => this.handleRemoveImage(2)} style={{position: 'absolute', bottom: '-5px', right: '-20px'}}>
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>}</div>}
                                            {this.state.incident && this.state.incident.remark.photos[2] && <div style={{float: 'left', marginRight: '10px', marginTop: '10px', position: 'relative'}}><SimpleImageInput id={'incidentphoto3'} onChange={this.handleImageUpload} url={(this.state.incident && this.state.incident.remark.photos[3]) ? this.state.incident.remark.photos[3].url : ''} />
                                                {(this.state.incident && this.state.incident.remark.photos[3]) && <IconButton   className="drawer-button" onClick={() => this.handleRemoveImage(3)} style={{position: 'absolute', bottom: '-5px', right: '-20px'}}>
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>}</div>}
                                            {this.state.incident && this.state.incident.remark.photos[3] && <div style={{float: 'left', marginRight: '10px', marginTop: '10px', position: 'relative'}}><SimpleImageInput id={'incidentphoto4'} onChange={this.handleImageUpload} url={(this.state.incident && this.state.incident.remark.photos[4]) ? this.state.incident.remark.photos[4].url : ''} />
                                                {(this.state.incident && this.state.incident.remark.photos[4]) && <IconButton   className="drawer-button" onClick={() => this.handleRemoveImage(4)} style={{position: 'absolute', bottom: '-5px', right: '-20px'}}>
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>}</div>}
                                        </div>}
                                        {this.props.incident && this.props.incident.remark.photos.map(photo => <img style={{float: 'left', marginRight: '10px', marginTop: '10px'}} src={photo.url} alt={'foto'} width="128px" height="128px"/>) }
                                        <br/>

                                    </div>
                                )}
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '76px',
                            }}
                        >
                            <p style={{ marginBottom: '-4px' }}>
                                <b>{t('KAM Status')}</b>
                            </p>
                            <p style={{ marginBottom: '-4px' }}>
                                {t('Resolved by KAM?')}{' '}
                                <div
                                    style={{
                                        float: 'right',
                                        marginTop: '-20px',
                                        marginRight: '50px',
                                    }}
                                >
                                    <Switch
                                        checked={
                                            this.state.incident &&
                                            this.state.incident.kamResolved
                                        }
                                        onChange={this.editIncident(
                                            'resolvedByKam'
                                        )}
                                        id="resolvedByKam"
                                    />
                                </div>
                            </p>
                        </div>

                        <div
                            style={{
                                marginLeft: '24px',
                                marginTop: '24px',
                                marginBottom: '24px',
                            }}
                        >
                            <FormControl
                                className={classes.item}
                                style={{ width: '505px' }}
                            >
                                <InputLabel htmlFor="incident-cause-comment">
                                    {t('Enter KAM Comment')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}

                                    id="kam-comment"
                                    value={
                                        this.state.incident &&
                                        this.state.incident.kamComment
                                            ? this.state.incident.kamComment
                                            : ''
                                    }
                                    onChange={this.editIncident('kam-comment')}
                                    multiline
                                />
                            </FormControl>

                        </div>


                    </div>
                )}
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};


export default connect(mapStateToProps, mapDispatchToProps)(IncidentEditView);
